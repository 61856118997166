@font-face {
  font-family: 'VarelaRound';
  src: local('VarelaRound'), url(./assets/fonts/Varela_Round/VarelaRound-Regular.ttf) format('truetype');
}

html, body,#root {
	height: 100%;
	width: 100%;
	user-select: none;
	font-size: 18px;
	
	--swiper-theme-color: white;

	--app-bar-color: #4a9da7;
	--me-bubble-color: #a8e2e7;
	--other-bubble-color: #a8e7b2;
	--bubble-note-margin: 10px;

	--page-bg: #b6c8b0;
	--page-bg-dark: #4E6A44;

	overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'VarelaRound';
/* 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.rtl {
	/* background-color: red; */
	direction: rtl;
}

.panel-cell-container {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	/* width: 100%; */
	/* background-color: purple; */
	position :relative;
}

.panel-fixed-cell {
	/* background-color: yellow; */
	/* width: 100%; */
	left: 0px;
	right: 0px;
}

.panel-main-cell {
	/* background-color: cyan; */
	left: 0px;
	right: 0px;
	flex: 1;
	flex-shrink: 1;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
}


.panel-grid-cell {
	/* background-color: cyan; */
	left: 0px;
	right: 0px;
	flex: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
}

.fill-flex-cell {
	/* position: absolute; */
	flex: 1; /* new */
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: scroll;
	-webkit-overflow-scrolling:touch;
}

.scroll-div {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: scroll;
	-webkit-overflow-scrolling:touch;
}

.AppBarCard {
	background-color: var(--app-bar-color);
	/* background-image: url(./assets/images/spring-bg.png); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.searchmatch {
  background-color: lightyellow;
}

.NoteCard {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 12px;
	margin-top: var(--bubble-note-margin);
	margin-bottom: var(--bubble-note-margin);
	/* width: 100px; */
	width: 100%;
}

/* Speech bubble CSS from https://leaverou.github.io/bubbly/ */

.speech-bubble.other {
	position: relative;
	background: var(--other-bubble-color);
	border-radius: .4em;
	flex-grow: 1;
	margin-right: 36px;
	margin-left: 18px;
}

.speech-bubble.other:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 21px solid transparent;
	border-right-color: var(--other-bubble-color);
	border-left: 0;
	border-bottom: 0;
	margin-top: -10.5px;
	margin-left: -21px;
}

.speech-bubble.me {
	position: relative;
	background-color: var(--me-bubble-color);
	border-radius: .4em;
	flex-grow: 1;
	margin-right: 18px;
	margin-left: 36px;
}

.speech-bubble.me:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 21px solid transparent;
	border-left-color: var(--me-bubble-color);
	border-right: 0;
	border-bottom: 0;
	margin-top: -10.5px;
	margin-right: -21px;
}

.speech-bubble.bottom {
	position: relative;
	background: var(--me-bubble-color);
	border-radius: .4em;
	margin-bottom: 36px;
}

.speech-bubble.bottom:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 70%;
	width: 0;
	height: 0;
	border: 27px solid transparent;
	border-top-color: var(--me-bubble-color);
	border-bottom: 0;
	border-left: 0;
	margin-left: -13.5px;
	margin-bottom: -27px;
}

.swiper-button-next {
	text-shadow: 0px 0px 4px #333;
}

.MuiTypography-h5 {
	font-size: 1.2rem;
}

/* .MuiCard-root {
	border: 1pt solid #CCC6;
	background-color: #fdfdfd;
} */

.MuiCardHeader-root {
	padding: 8px;
	/* background-color: var(--app-bar-color); */
}

.MuiCardHeader-title {
	/* color: white; */
}

.MuiCardContent-root {
	/* padding: 8px; */
	padding-top: 8px;
	padding-bottom: 0px;
}